<template>
  <div>
    <b-form-group
      :label="label"
      label-size="lg"
      label-class="font-weight-bold pt-0"
      >
    <div class="d-flex">
      <div class="w-50">
        <label>{{ notSelectedLabel }}</label>
      </div>
      <div class="w-50">
        <label>{{ selectedLabel }} ({{ selected.length }})</label>
      </div>
    </div>
    <div class="border d-flex split-list">
      <div class="w-50 d-flex flex-column">
        <div class="bg-light p-3">
          <b-form-input
            :placeholder="filterLabel"
            v-model="filterQuery"
          ></b-form-input>
        </div>
        <div class="py-2 overflow-auto flex-1">
          <div
            v-for="(option, index) in searchFilter(notSelected, filterQuery)"
            :key="option[valueKey]"
            class="d-flex align-items-center border-left-0 border-right-0 justify-content-between py-2 pl-3 pr-2 border-top"
            :class="{ 'border-top-0': index === 0, 'border-bottom-0': index === searchFilter(notSelected, filterQuery).length - 1 }">
            <div>
              <slot v-bind:option="option">
                {{ option[valueKey] }}
              </slot>
            </div>
            <b-button size="sm" variant="link" @click.prevent="addToSelection(option[valueKey])">
              <b-icon
                scale="1.5"
                icon="plus-circle"
                variant="secondary">
              </b-icon>
            </b-button>
          </div>
        </div>
      </div>
      <div class="w-50 border-left py-2 overflow-auto">
        <div v-if="selected.length">
          <div
            v-for="(option, index) in selected"
            :key="option[valueKey]"
            class="d-flex align-items-center border-left-0 border-right-0 justify-content-between py-2 pl-3 pr-2 border-top"
            :class="{ 'border-top-0': index === 0, 'border-bottom-0': index === selected.length - 1 }">
            <div>
              <slot v-bind:option="option">
                {{ option[valueKey] }}
              </slot>
            </div>
            <b-button size="sm" variant="link" @click.prevent="removeFromSelection(option[valueKey])">
              <b-icon
                scale="1.5"
                icon="x-circle"
                variant="secondary">
              </b-icon>
            </b-button>
          </div>
        </div>
        <div v-else class="justify-content-between p-3 font-weight-bold">
          {{ nothingSelectedMessage }}
        </div>
      </div>
    </div>
    </b-form-group>
  </div>
</template>

<script>
export default {
  name: 'SplitListSelect',
  props: [
    'label',
    'options',
    'value',
    'valueKey',
    'filterLabel',
    'searchFilter',
    'notSelectedLabel',
    'selectedLabel',
    'nothingSelectedMessage'
  ],
  data() {
    return {
      selection: this.value,
      filterQuery: '',
    }
  },
  computed: {
    notSelected() {
      return this.options.filter(option => this.selection.indexOf(option[this.valueKey]) === -1)
    },
    selected() {
      return this.options.filter(option => this.selection.indexOf(option[this.valueKey]) !== -1)
    }
  },
  methods: {
    addToSelection(value) {
      this.selection = [
        ...this.selection,
        value
      ];
      this.update();
    },
    removeFromSelection(value) {
      this.selection = [
        ...this.selection.filter(optionValue => optionValue !== value)
      ];
      this.update();
    },
    update() {
      this.$emit('input', this.selected.map(option => option[this.valueKey]));
    }
  },
  watch: {
    value(newVal) {
      this.selection = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
  @import './kit/core/mixins.scss';
  .flex-1 {
    flex: 1;
  }
  .split-list {
    height: 300px;
  }
</style>
